<template>
  <v-form>
    <v-container fluid>
      <!--  Include Annotations -->
      <v-checkbox
        v-model="includeAnnotations"
        :label="labelIncludeAnnotations"
        :disabled="!enableIncludeAnnotations"
      ></v-checkbox>

      <!-- Annotation Option -->
      <v-sheet class="mt-0 ml-12">
        <!-- annotations Burn -->
        <v-switch
          v-model="annotationsBurn"
          :label="labelAnnotationsBurn"
        ></v-switch>

        <!-- Maintain Annotation Color -->
        <v-checkbox
          class="mt-0 ml-8"
          v-model="maintainColor"
          :label="labelMaintainColor"
          :messages="noteMaintainColor"
          :disabled="!enableMaintainAnnotationColor"
        ></v-checkbox>
      </v-sheet>

      <!--  include Redactions -->
      <v-checkbox
        class="mt-0"
        v-model="includeRedaction"
        :label="labelIncludeRedaction"
        :disabled="!enableIncludeRedactions"
      ></v-checkbox>

      <!--  burn Redactions -->
      <v-checkbox
        class="mt-0 ml-12"
        v-model="burnRedaction"
        :label="labelBurnRedaction"
        :disabled="!enableBurnRedactions"
      ></v-checkbox>
    </v-container>
  </v-form>
</template>

<script>
import {
  annotateOption,
  createAnnotateOption,
  findAnnotateOption
} from "@/model/image/imageModel";

export default {
  name: "DownloadAnnotationOption",
  props: {
    /**
     * @type {{includeAnnotations: boolean, burnIntoImage: boolean, maintainColor: boolean, includeRedaction: boolean, burnRedaction: boolean}}
     */
    annotateOption: createAnnotateOption(),

    /**
     * is current user authorized to manage Annotations
     */
    manageAnnotations: {
      type: Boolean,
      default: false
    },

    /**
     * is current user authorized to manage Redactions
     */
    manageRedactions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelIncludeAnnotations() {
      return findAnnotateOption(annotateOption.annotationsInclude)?.label;
    },

    /**
     * Get/set include Annotations
     */
    includeAnnotations: {
      get() {
        return this.annotateOption.includeAnnotations;
      },
      set(value) {
        this.annotateOption.includeAnnotations = value;
      }
    },

    /**
     * label Annotations Burn
     * @return {string}
     */
    labelAnnotationsBurn() {
      return findAnnotateOption(annotateOption.annotationsBurn)?.label;
    },

    /**
     * Get/set maintain Color
     */
    annotationsBurn: {
      get() {
        return this.annotateOption?.burnIntoImage ?? false;
      },
      set(value) {
        this.annotateOption.burnIntoImage = value;
      }
    },

    /**
     * label Annotations Burn
     * @return {string} label Burn Annotations
     */
    labelMaintainColor() {
      return findAnnotateOption(annotateOption.annotationsBurnMaintainColor)
        ?.label;
    },

    /**
     * note Maintain Color
     * @return {string} note Maintain Color
     */
    noteMaintainColor() {
      const note = findAnnotateOption(
        annotateOption.annotationsBurnMaintainColor
      )?.note;
      return `Note: ${note}`;
    },

    /**
     * Get/set maintain Color
     * remarks: just an example of using a model and its fields
     * (if we prefer to use instead of strongly typed: this.annotateOption.maintainColor,
     * which is defined by object type interface)
     */
    maintainColor: {
      get() {
        return this.annotateOption[annotateOption.annotationsBurnMaintainColor];
      },
      set(value) {
        this.annotateOption[
          annotateOption.annotationsBurnMaintainColor
        ] = value;
      }
    },

    /**
     * label Include Redaction
     * @return {string}
     */
    labelIncludeRedaction() {
      return findAnnotateOption(annotateOption.redactionsInclude)?.label;
    },

    /**
     * Get/set include Redaction
     */
    includeRedaction: {
      get() {
        return this.annotateOption.includeRedaction;
      },
      set(value) {
        this.annotateOption.includeRedaction = value;
      }
    },

    /**
     * label Burn Redaction
     * @return {string}
     */
    labelBurnRedaction() {
      return findAnnotateOption(annotateOption.redactionsBurn)?.label;
    },

    /**
     * Get/set burn Redaction
     */
    burnRedaction: {
      get() {
        return this.annotateOption.burnRedaction;
      },
      set(value) {
        this.annotateOption.burnRedaction = value;
      }
    },

    enableIncludeAnnotations() {
      return this.manageAnnotations;
    },

    enableAnnotationOption() {
      return this.manageAnnotations ? this.includeAnnotations : false;
    },

    enableMaintainAnnotationColor() {
      return (
        this.includeAnnotations && (this.annotateOption?.burnIntoImage ?? false)
      );
    },

    enableIncludeRedactions() {
      return this.manageRedactions;
    },

    enableBurnRedactions() {
      return this.manageRedactions ? this.includeRedaction : false;
    },

    annotateOptionToString() {
      return JSON.stringify(this.annotateOption);
    }
  },
  watch: {
    annotationsBurn(newValue) {
      if (!newValue) {
        this.maintainColor = false;
      }
    },
    includeAnnotations(newValue) {
      if (!newValue) {
        this.annotationsBurn = false;
        this.maintainColor = false;
      }
    },
    includeRedaction(newValue) {
      if (!newValue) {
        this.burnRedaction = false;
      }
    }
  }
  // created() {
  //   console.log(
  //     `${this.$options.name} this.annotateOption:`,
  //     this.annotateOption
  //   );
  // }
};
</script>

<style scoped></style>
